/* NAVBAR */
.navbar-collapse {
  background-color: #1369b1;
}

#link-header {
  font-weight: 600;
  color: white;
  font-size: 20px;
  margin-right: 40px; 
  margin-left: 40px;
}

#link-header:hover {
  color: #91eae4;
}

.custom-navbar{
  background-color: #1369b1;
}


.navbar-toggler {
  margin-bottom: 5px;
  margin-left: 15px;
}




/* BANNER */

.banner {
  width: 100%; 
  text-align: center;
  overflow: hidden;
}


.banner-logo {
  width: 100%; 
  height: auto;
  transition: transform 0.3s ease; 
}

@media (max-width: 768px) {
  .banner-logo {
    transform: scale(1.5); 
  }
}

/* OUR SERVICES */

.imagen-con-titulo {
  text-align: center;
}

.imagen-con-titulo img {
  width: 150px;
  height: auto;
  margin: 0 60px; 
  margin-top: 40px;
  transition: transform 0.3s ease; 
}

.imagen-con-titulo img:hover {
  transform: scale(1.3); 
}



.imag-container {
  flex-wrap: wrap;
  display: flex; 
  justify-content: center; 
  align-items: flex-start;
  /* padding-top: 60px; */
  padding-bottom: 50px;
}

@media only screen and (max-width: 1005px) {
  .imag-container {
    padding-top: 20px;
    margin-top:0px;
}
}


.imag-container2 {
  flex-wrap: wrap;
  display: flex; 
  justify-content: center; 
  align-items: flex-start;
  padding-top: 60px;
  padding-bottom: 50px;
}

@media only screen and (max-width: 1005px) {
  .imag-container2 {
    padding-top: 20px;
    margin-top:0px;
}
}



.ourServices {
  background-color: #f7f7f794;
  padding-top: 90px;
}

@media (max-width: 768px) {
  .ourServices {
    padding-top: 40px;
  }
}


.titulo-services {
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px; 
  max-width: 200px; 
  white-space: normal; 
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}


.titulo-services {
  color: #1369b1;
}

.Our-Services {
  padding-top:200px;
  margin-right:auto;
  margin-left:auto;
  color: white;
  font-weight: 600;
  text-align: center;
  padding-top: 8px; 
  padding-bottom: 8px;
  font-size: 45px;
  /* margin-top: 80px; */
  padding-right: 20px;
  padding-left: 20px;
  /* display: inline-block; */
  background-color: #1369b1;
  max-width: 815px;
  border-radius: 10px;
  height: auto;
  transition: font-size 0.3s ease, margin-top 0.3s ease, padding 0.3s ease;
}

.our-services-txt {
margin-left: auto;
margin-top: 70px;
margin-right:auto;
font-size: 20px;
max-width: 1000px;
text-align: center;
font-family: 'Quicksand', sans-serif;
font-weight: 500;
}




@media (max-width: 768px) {
  .our-services-txt {
    font-size: 19px; 
    margin-top: 50px;
    margin-right: 8px;
    margin-left: 8px;
  }
}

@media (max-width: 768px) {
  .Our-Services {
    font-size: 30px; 
    /* margin-top: 40px;  */
    padding-right: 10px; 
    padding-left: 10px;
  }
}


/* OUR PRODUCTS */
/* for our products I used same css than our services */

.ourProduct {
  padding-top:80px;
}

@media (max-width: 768px) {
  .ourProduct {
    padding-top: 40px;
  }
}




/* PHOTO GALLERY */

.margin-title {
  padding-top:60px;
}

div.gallery {
  border: 3px solid rgba(255, 255, 255, 0);
  transition: transform 0.1s, border 0.1s, opacity 0.1s;

}

div.gallery:hover {
  /* border: 1px solid white; */
  filter: brightness(1.3);
  transform-origin: center center;
}



div.gallery img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

div.desc {
  padding: 15px;
  text-align: center;
}



.responsive {
  float: left;
  width: 24.99999%;
  position: relative;
}

@media only screen and (max-width: 700px) {
  .responsive {
    width: 49.99999%;
    margin: 6px 0;
  }
}

@media only screen and (max-width: 500px) {
  .responsive {
    width: 100%;
  }
}




.close {
  background-color: #1369b1;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.close:hover {
  background-color: #1369b1;
}




.GalleryHire {
  max-width: 1200px;
  margin-left: auto;
  margin-right:auto; 
  margin-top:70px;
}



@media only screen and (max-width: 768px) {
  .GalleryHire {
    margin-top:50px;
  }
}



.gallery-section {
  background-image: url('../public/img/background-gallery.png');
  background-size: cover; 
  background-repeat: no-repeat; 
  background-position: center;
  background-attachment: fixed;
  color: white;
  margin-top: 100px;
  padding-bottom: 100px;
}

@media only screen and (max-width: 768px) {
  .gallery-section {
    margin-top:60px;
  }
}


.GalleryHire {
  padding:20px
}


.load-more-button {
  width: 180px;
  height: auto;
  background-color: #1369b1;
  border-color: #1369b1;
  color: white;
  font-size: 25px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 50px;
margin-left: auto;
border-radius: 10px;
}

.load-more-button:hover {
  background-color: #91eae4;
  border-color:#91eae4;
}


/* CONTACT US */


#Contact {
  padding-top: 50px;
  padding-bottom: 50px;
  max-width: 1200;
  min-width: 200px;
}

.info-email {
  text-align: left;
}


.contact-txt {
  text-align: center;
  max-width: 1000px;
  margin-left: auto;
  margin-right:auto;
  height: auto;
}

.contact-txt p{
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 50px;
  margin-top: 50px;
  margin-right:10px;
  margin-left:10px;
}

.info-email h4 {
  /* font-family: 'Quicksand', sans-serif; */
  font-weight: 600;
}

.info-email h6 {
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  font-size: 18px;
}

#btn {
  background-color: #1369b1;
  border-color: #1369b1;
}

#btn:hover {
  background-color: #91eae4;
  color:#1369b1;
  border-color:#91eae4;
}



/* FOOTER */

.list-unstyled {
  font-family: 'Quicksand-Medium', sans-serif;
  /* font-weight: 600; */
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px;
  padding-bottom: 20px;
  padding-top: 20px;
  background-color:#1369b1;
}

.link-item a:hover {
  color:#91eae4;
}





.linkFooter {
  margin-right: 30px;
  margin-left:30px;
  color:white;
}

/* Estilos para pantallas pequeñas */
@media (max-width: 768px) {
  .links-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .link-item {
    margin-bottom: 10px;
  }

  .linkFooter {
    font-size: 15px;
  }
  .list-unstyled {
    font-size: 12px;
    padding-bottom: 10px;
    padding-top: 20px;
}
.text-center {
  font-size: 13px;
}
}


@media (min-width: 769px) {
  .links-container {
    flex-direction: row;
    justify-content: center;
  }

  .link-item {
    margin-right: 20px;
  }

  .linkFooter {
    font-size: 18px;
  }
}


.contact-container {
  max-width: 819px;
    margin-left: auto;
    margin-right: auto;
    border-style: outset;
    margin-top: 20px;
    /* border: 2px solid #1369b1; */
    border-radius: 10px;
}

